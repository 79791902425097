import { Module } from 'vuex';

import { Control } from '@/services/controls/controls.types';
import { devicesService } from '@/services/devices/devices.service';
import { Device, RelocateOptions } from '@/services/devices/devices.types';
import { dicoLabelsService } from '@/services/dico-labels/dico-labels.service';
import { formsService } from '@/services/forms/forms.service';
import { RootState } from '@/store';

import { AppSectionCode } from '@/services/app-sections/app-sections.types';
import { Grade } from '@/services/grades/grades.types';
import { gradesService } from '@/services/grades/grades.service';
import { reportsService } from '@/services/reports/reports.service';
import { Report } from '@/services/reports/reports.types';

export interface DeviceState {
  device: Device;
  deviceLoading: boolean;
  controlLabelLoading: boolean;
  controlReports: Report[];
  grades: Grade[];
}

const state: DeviceState = {
  device: null,
  deviceLoading: false,
  controlLabelLoading: false,
  controlReports: [],
  grades: []
};

const options: Module<DeviceState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setDevice: ({ commit, dispatch }, device: Device | string | number): Promise<void> => {
      if (typeof device === 'number' || typeof device === 'string') return dispatch('getDeviceBySerialNo', device);
      else commit('device', device);
    },
    newDevice: ({ commit, dispatch }): Promise<void> => {
      commit('device', null);
      commit('deviceLoading', true);
      return devicesService
        .newDevice()
        .then(device => device && commit('device', device))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    createDevice: ({ commit, dispatch }, device: Device): Promise<void> => {
      commit('deviceLoading', true);
      return devicesService
        .create(device)
        .then(device => device && commit('device', device))
        .then(() => dispatch('alert/pushSuccess', 'Ordinateur créé avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    getDeviceBySerialNo: ({ commit, dispatch }, serialNo: string): Promise<void> => {
      commit('deviceLoading', true);

      return devicesService
        .get(serialNo)
        .then(device => device && commit('device', device))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    findDeviceBySerialNo: (
      { commit, dispatch },
      options: { serialNo: string; stocks?: AppSectionCode[] }
    ): Promise<void> => {
      commit('deviceLoading', true);
      const { serialNo, stocks } = options;

      return devicesService
        .findBySerialNo(serialNo, { stocks })
        .then(device => device && typeof device === 'object' && dispatch('setDevice', device))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    checkControlStatus: (
      { commit, dispatch },
      { imei, serialNo, selectedReference }: { imei: string; serialNo: string; selectedReference?: string }
    ): Promise<void> => {
      commit('setDeviceLoading', true);
      return devicesService
        .checkDeviceControl(imei, serialNo, selectedReference)
        .then(device => {
          if (device) {
            commit('setDevice', device);
          } else {
            dispatch('alert/pushError', 'Device not found or not under control', { root: true });
          }
        })
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('setDeviceLoading', false));
    },
    getDeviceFromControl: ({ commit, dispatch }, control: Control): Promise<void> => {
      commit('deviceLoading', true);
      return devicesService
        .get(control.serial_no)
        .then(device => {
          if (device) {
            commit('device', {
              ...device,
              ...control,
              initial_comment: device.initial_comment,
              created_at: device.created_at,
              updated_at: device.updated_at
            });
            state.device.supplier_reference = device.supplier_reference;
            state.device.supplier_id = device.supplier_id;
            state.device.purchase_price = device.purchase_price;
          } else commit('device', control);
        })
        .catch(error => {
          if (error.status === 404) commit('device', control);
          else dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => commit('deviceLoading', false));
    },
    saveReport: ({ commit, dispatch }, control_report_id: number): Promise<void> => {
      commit('deviceLoading', true);
      return devicesService
        .downloadReport(control_report_id)
        .then(report => report && dispatch('file/saveBase64As', report, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    generateNewReport: ({ commit, dispatch }, { deviceId, step }): Promise<void> => {
      commit('deviceLoading', true);
      return devicesService
        .getReport(deviceId, step)
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    initDevice: async (
      { commit, dispatch },
      { control, device }: { control: Control; device: Device }
    ): Promise<Device> => {
      commit('deviceLoading', true);
      return devicesService
        .createFromControl(control.id, { ...control, ...device, control_id: control.id })
        .then(device => {
          if (device) {
            commit('device', device);

            return device;
          } else {
            throw new Error('Device is undefined after creation');
          }
        })
        .then(() => dispatch('alert/pushSuccess', 'Contrôle validé !', { root: true }))
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
          throw error;
        })
        .finally(() => commit('deviceLoading', false));
    },
    updateDevice: ({ commit, state, dispatch }, data: Device): Promise<void> => {
      commit('deviceLoading', true);
      return devicesService
        .update(state.device.serial_no, data)
        .then(device => device && commit('device', device))
        .then(() => dispatch('alert/pushSuccess', 'Ordinateur mis à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    deleteDevice: ({ commit, dispatch }, serialNo: string): Promise<void> => {
      commit('deviceLoading', true);
      return devicesService
        .delete(serialNo)
        .then(() => commit('device', null))
        .then(() => dispatch('alert/pushSuccess', 'Ordinateur supprimé !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    putInStock: async (
      { commit, state, dispatch },
      { stockId, reason }: { stockId: number; reason: string }
    ): Promise<void> => {
      commit('deviceLoading', true);
      return devicesService
        .move(state.device.serial_no, stockId, reason)
        .then(device => {
          commit('device', device);
        })
        .then(() => dispatch('alert/pushSuccess', "L'ordinateur a bien été déplacé !", { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    relocateDevice: ({ dispatch }, options: RelocateOptions): Promise<any> => {
      const { stockId, reason } = options;
      return dispatch('putInStock', { stockId, reason });
    },
    printControlLabel: ({ commit, dispatch }, serialNo: string): Promise<void> => {
      commit('controlLabelLoading', true);
      return devicesService
        .getLabel(serialNo)
        .then(zpl => zpl && dispatch('printer/printWith', { data: [zpl], printer: 'CONTROL' }, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('controlLabelLoading', false));
    },
    printMiniLabel: ({ commit, dispatch }, serialNo: string): Promise<void> => {
      commit('controlLabelLoading', true);
      return devicesService
        .getMiniLabel(serialNo)
        .then(zpl => zpl && dispatch('printer/printWith', { data: [zpl], printer: 'MINI' }, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('controlLabelLoading', false));
    },
    clearDevice: ({ dispatch }): Promise<void> => {
      return dispatch('setDevice', null);
    },
    importUpdateStatus: ({ commit, dispatch }, { file }: { file: File }): Promise<void> => {
      commit('deviceLoading', true);
      return devicesService
        .importUpdateStatus(file)
        .then(resp =>
          dispatch(
            'alert/pushSuccess',
            `${resp.count} modification${resp.count > 1 ? 's' : ''} importée${resp.count > 1 ? 's' : ''} !`,
            { root: true }
          )
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    getDeviceControlReports: ({ commit, dispatch }, serial_no: string): Promise<void> => {
      commit('deviceLoading', true);
      return reportsService
        .list(serial_no)
        .then(control_reports => control_reports && commit('controlReports', control_reports))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    },
    getDeviceGrades: ({ commit, dispatch }, serial_no: string): Promise<void> => {
      commit('deviceLoading', true);
      return gradesService
        .list(serial_no)
        .then(grades => grades && commit('grades', grades))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('deviceLoading', false));
    }
  },
  mutations: {
    device: (state, device) => (state.device = device),
    deviceLoading: (state, loading) => (state.deviceLoading = loading),
    controlLabelLoading: (state, loading) => (state.controlLabelLoading = loading),
    controlReports: (state, loading) => (state.controlReports = loading),
    grades: (state, loading) => (state.grades = loading)
  },
  getters: {
    controlForm: (state, getters, rootState) =>
      formsService.getForm('control', state.device, rootState['global-settings'].settings),
    controlRelocateForm: (state, getters, rootState) =>
      formsService.getForm('control-relocate', state.device, rootState['global-settings'].settings),
    deviceCreationForm: (state, getters, rootState) =>
      formsService.getForm('device-creation', state.device, rootState['global-settings'].settings),
    deviceForm: (state, getters, rootState) =>
      formsService.getForm('device', state.device, rootState['global-settings'].settings),
    operatorName: state => dicoLabelsService.formatName(state.device?.operator)
  }
};

export default options;
